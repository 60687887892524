import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import {
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import { TrashIcon } from '../../../components/Icons';
import { FieldProperties } from '../ExtractionCreateEditModal/types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Combobox from '../../../components/Core/Combobox';

type AIEntity = {
  name: string;
  field_id: string;
  criteria: string;
  options?: { value: string; label: string }[];
  default_option?: string;
};

type Props = {
  type: string;
  entity: AIEntity;
  labels?: { [key: string]: string };
  helpers?: { [key: string]: string };
  usedFields: string[];
  saveToOptions: { label: string; value: string }[];
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  onUpdate: (key: string | object, value?: any) => void;
  onRemove: () => void;
};

const Entity = ({
  type,
  entity,
  labels: _labels = {},
  helpers: _helpers = {},
  usedFields,
  saveToOptions: _saveToOptions,
  fieldProperties,
  hiddenFieldProperties,
  onUpdate,
  onRemove
}: Props) => {
  const [showAdvanced, setShowAdvanced] = useState(!!entity.options?.length);
  const [criteria, setCriteria] = useState(entity.criteria || '');
  const deferredEntity = useDeferredValue<any>(entity);
  const getCriteria = useSyncedRefState(criteria);
  const getEntity = useSyncedRefState(entity);
  const isPageNumberQuery = type === 'page_number';

  const labels = {
    name: 'Entity',
    details: 'Details',
    ..._labels
  };

  const helpers = {
    name: 'The name of the variable you want to extract',
    details: '(Optional) Additional entity details to tune the extraction',
    ..._helpers
  };

  const allFieldProperties = {
    ...fieldProperties,
    ...hiddenFieldProperties
  };

  useEffect(() => {
    if (allFieldProperties[entity.field_id]?.options) {
      onUpdate('options', allFieldProperties[entity.field_id].options);
    }
  }, []);

  useEffect(() => {
    if (entity.field_id && deferredEntity?.field_id !== entity.field_id) {
      const _entity = getEntity();
      const props = allFieldProperties[entity.field_id];
      const deferredProps = allFieldProperties[deferredEntity.field_id] || {};
      const canUpdateCriteria =
        !_entity.criteria ||
        (!getCriteria() &&
          deferredProps?.semanticDescription === _entity.criteria) ||
        getCriteria() === deferredProps?.semanticDescription;

      const data = {
        options: deferredEntity?.options,
        criteria: deferredEntity?.criteria,
        default_option: deferredEntity?.default_option
      };

      data.options = props?.options ? props.options : [];

      if (
        data.default_option &&
        !data.options.find((o: any) => o.value === data.default_option)
      ) {
        data.default_option = '';
      }

      if (canUpdateCriteria) {
        if (props?.semanticDescription) {
          data.criteria = props.semanticDescription;
          setCriteria('');
        } else {
          data.criteria = '';
          setCriteria('');
        }
      }

      onUpdate(data);
      setShowAdvanced(!!data.options?.length);
    }
  }, [entity.field_id, deferredEntity, setShowAdvanced]);

  const saveToOptions = useMemo(() => {
    return _saveToOptions.filter(
      (option) =>
        option.value === entity.field_id || !usedFields.includes(option.value)
    );
  }, [usedFields, _saveToOptions, entity.field_id]);

  const handleCriteriaChange = (newVal: string) => {
    onUpdate('criteria', newVal);
    setCriteria(newVal);
  };

  const fieldOptions = allFieldProperties[entity.field_id]?.options?.length
    ? allFieldProperties[entity.field_id].options
    : entity?.options || [];

  return (
    <div
      className={classNames(styles.keyValuePairs, styles.card, styles.white)}
    >
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          {labels.name}{' '}
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text={helpers.name}
          />
        </div>
        <div className={styles.value}>
          <TextField
            value={entity.name}
            placeholder={
              isPageNumberQuery ? 'Active Holding Pages' : 'Stock Holding Name'
            }
            onComplete={(newVal: string) => onUpdate('name', newVal)}
            required
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.action} onClick={onRemove}>
            <TrashIcon width={16} height={16} />
          </div>
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          {labels.details}{' '}
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text={helpers.details}
          />
        </div>
        <div className={styles.value}>
          <TextField
            value={entity.criteria}
            placeholder={
              isPageNumberQuery
                ? 'Pages that include active holdings'
                : 'Holdings will include the ticker symbol'
            }
            onComplete={handleCriteriaChange}
            required={isPageNumberQuery}
          />
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          Save To{' '}
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text={
              isPageNumberQuery
                ? 'The field to save the page numbers to'
                : 'The field to save the extracted value to'
            }
          />
        </div>
        <div className={styles.value}>
          <DropdownField
            selected={entity.field_id ?? ''}
            options={saveToOptions.map((option) => ({
              value: option.value,
              display: option.label
            }))}
            onChange={(event: any) => {
              const val = event.target.value;
              onUpdate('field_id', val || null);
            }}
          />
        </div>
      </div>
      {!isPageNumberQuery && (
        <CollapsibleSection
          title='Advanced Options'
          expanded={showAdvanced}
          onToggle={() => setShowAdvanced(!showAdvanced)}
          separator={false}
          customClasses={{
            header: styles.advancedSectionHeader,
            title: styles.advancedSectionTitle,
            content: styles.advancedSectionContent,
            expandIndicator: styles.advancedSectionExpandIndicator
          }}
        >
          <>
            <div className={styles.keyValuePair}>
              <div className={styles.key}>
                Options{' '}
                <InlineTooltip
                  size={15}
                  style={{ marginTop: '-2px' }}
                  text='(Optional) Specific values for the entity to be extracted from the document'
                />
              </div>
              <div className={styles.value}>
                <Combobox
                  value={fieldOptions?.map(({ label }) => label) || []}
                  onChange={(items: string[]) => {
                    const newOptions = items.map((label) => ({
                      label,
                      value:
                        fieldOptions?.find(({ label: l }) => l === label)
                          ?.value ?? label
                    }));
                    onUpdate('options', newOptions);
                  }}
                  isDisabled={!!allFieldProperties[entity.field_id]?.options}
                  placeholder='Add options...'
                  classNames={{
                    multiFieldValueContainer: styles.multiFieldValueContainer,
                    multiFieldMultiValue: styles.multiFieldValue,
                    multiFieldMultiValueLabel: styles.multiFieldValueLabel,
                    multiFieldInput: styles.multiFieldInput,
                    multiFieldPlaceholder: styles.multiFieldPlaceholder,
                    multiFieldIndicatorsContainer:
                      styles.multiFieldIndicatorsContainer,
                    multiFieldControl: (state: any) => {
                      return classNames({
                        [styles.multiFieldControl]: true,
                        [styles.multiFieldControlDisabled]: state.isDisabled,
                        [styles.multiFieldControlFocused]: state.isFocused
                      });
                    }
                  }}
                />
              </div>
            </div>
            {!!fieldOptions?.length && (
              <div className={styles.keyValuePair}>
                <div className={styles.key}>
                  Default{' '}
                  <InlineTooltip
                    size={15}
                    style={{ marginTop: '-2px' }}
                    text='Specify the default option to fallback to if the answer is unclear or not found.'
                  />
                </div>
                <div className={styles.value}>
                  <DropdownField
                    selected={entity.default_option}
                    options={fieldOptions.map(({ value, label }) => ({
                      value,
                      display: label
                    }))}
                    onChange={(event: any) =>
                      onUpdate('default_option', event.target.value)
                    }
                  />
                </div>
              </div>
            )}
          </>
        </CollapsibleSection>
      )}
    </div>
  );
};

export default Entity;
