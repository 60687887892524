import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseAndThrowError } from './utils';

const getPanels = createAsyncThunk(
  'panels/getPanels',
  async ({ token, active }: { token: string; active?: boolean }) => {
    const response = await FeatheryAPI.getPanels(token, active);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch panels');
    }
  }
);

const createPanel = createAsyncThunk(
  'panels/createPanel',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createPanel(token, data);

    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not create panel');
    }
  }
);

const editPanel = createAsyncThunk(
  'panels/editPanel',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editPanel(token, data);

    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit panel');
    }
  }
);

const deletePanel = createAsyncThunk(
  'panels/deletePanel',
  async ({ token, panelId }: { token: string; panelId: string }) => {
    const response = await FeatheryAPI.deletePanel(token, { panelId });

    if (response.status !== 204) {
      throw new Error('Could not delete panel');
    }
  }
);

const editAccountPanelData = createAsyncThunk(
  'panels/editAccountPanelData',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editAccountPanelData(token, data);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not edit account panel data');
    }
  }
);

const inviteCollaborators = createAsyncThunk(
  'panels/inviteCollaborators',
  async ({
    token,
    panelId,
    collaborators,
    ...data
  }: {
    token: string;
    panelId: string;
    collaborators: any[];
  }) => {
    const response = await FeatheryAPI.inviteCollaborators(token, {
      panelId,
      collaborators,
      data
    });

    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not invite users');
    }
  }
);

const startCollaboratorSession = createAsyncThunk(
  'panels/startCollaboratorSession',
  async ({ token, panelId, ...data }: { token: string; panelId: string }) => {
    const response = await FeatheryAPI.startCollaboratorSession(
      token,
      panelId,
      data
    );

    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not start collaborator session');
    }
  }
);

const editMetaImage = createAsyncThunk(
  'panels/editMetaImage',
  async ({
    token,
    panelId,
    image
  }: {
    token: string;
    panelId: string;
    image?: any;
  }) => {
    const response = await FeatheryAPI.editMetaImage(token, panelId, image);

    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not upload/reset meta image');
    }
  }
);

const DEFAULT_FLOW_POSITION = { x: 0, y: 0, zoom: 1 };

const panelsSlice = createSlice({
  name: 'panels',
  initialState: {
    panels: {} as Record<string, any>,
    flowPositions: {} as Record<string, any>,
    flowStepPositions: {} as Record<
      string,
      Record<string, { x: number; y: number }>
    >
  },
  reducers: {
    setFlowPosition(state, action) {
      state.flowPositions[action.payload.id] = action.payload.position;
    },
    setFlowStepPositions(state, action) {
      const panelId = action.payload.id;
      if (!state.flowStepPositions[panelId])
        state.flowStepPositions[panelId] = {};
      state.flowStepPositions[panelId] = action.payload.positions;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPanels.fulfilled, (state, action) => {
      action.payload.forEach((panel: any) => {
        state.panels[panel.id] = panel;
        state.flowPositions[panel.id] = DEFAULT_FLOW_POSITION;
        state.flowStepPositions[panel.id] =
          panel.account.step_positions[panel.id] ?? {};
      });
    });
    builder.addCase(createPanel.fulfilled, (state: any, action: any) => {
      state.panels[action.payload.id] = action.payload;
      state.flowPositions[action.payload.id] = DEFAULT_FLOW_POSITION;
    });
    builder.addCase(editPanel.fulfilled, (state: any, action: any) => {
      state.panels[action.payload.id] = action.payload;
    });
    builder.addCase(deletePanel.fulfilled, (state: any, action: any) => {
      delete state.panels[action.meta.arg.panelId];
      delete state.flowPositions[action.meta.arg.panelId];
    });
    builder.addCase(
      editAccountPanelData.fulfilled,
      (state: any, action: any) => {
        state.panels[action.meta.arg.panelId].account = action.payload;
      }
    );
    builder.addCase(editMetaImage.fulfilled, (state: any, action: any) => {
      state.panels[action.meta.arg.panelId].seo_image = action.payload.image;
    });
  }
});

export default panelsSlice.reducer;
const exportFunctions = {
  syncFunctions: panelsSlice.actions,
  asyncFunctions: {
    getPanels,
    createPanel,
    editPanel,
    deletePanel,
    editAccountPanelData,
    inviteCollaborators,
    startCollaboratorSession,
    editMetaImage
  }
};
export { exportFunctions, DEFAULT_FLOW_POSITION };
