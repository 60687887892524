import { INTEGRATIONS } from '../../types';

export const ROLLOUT_APPS = {
  [INTEGRATIONS.AIRTABLE]: 'airtable',
  [INTEGRATIONS.DROPBOX]: 'dropbox',
  [INTEGRATIONS.GOOGLE_DOCS]: 'google-docs',
  [INTEGRATIONS.GOOGLE_SHEETS]: 'google-sheets',
  [INTEGRATIONS.KLAVIYO]: 'klaviyo',
  [INTEGRATIONS.MAILCHIMP]: 'mailchimp',
  [INTEGRATIONS.SENDGRID]: 'sendgrid',
  [INTEGRATIONS.NOTION]: 'notion',
  [INTEGRATIONS.PIPEDRIVE]: 'pipedrive',
  [INTEGRATIONS.SALESFORCE]: 'salesforce',
  [INTEGRATIONS.SLACK_V2]: 'slack',
  [INTEGRATIONS.HUBSPOT_V2]: 'hubspot',
  [INTEGRATIONS.ZOHO_V2]: 'zoho-crm',
  [INTEGRATIONS.ZENDESK]: 'zendesk',
  [INTEGRATIONS.ACTIVE_CAMPAIGN]: 'active-campaign',
  [INTEGRATIONS.ASANA]: 'asana',
  [INTEGRATIONS.BONZO]: 'bonzo',
  [INTEGRATIONS.CLICKUP]: 'clickup',
  [INTEGRATIONS.CLOSE]: 'close',
  [INTEGRATIONS.DISCORD]: 'discord',
  [INTEGRATIONS.FACEBOOK]: 'facebook',
  [INTEGRATIONS.FLOURISH]: 'flourish',
  [INTEGRATIONS.FOLLOW_UP_BOSS]: 'followup-boss',
  [INTEGRATIONS.FRESHSALES]: 'freshsales',
  [INTEGRATIONS.FRESHSERVICE]: 'freshservice',
  [INTEGRATIONS.GMAIL]: 'gmail',
  [INTEGRATIONS.GOOGLE_CALENDAR]: 'google-calendar',
  [INTEGRATIONS.INTERCOM]: 'intercom',
  [INTEGRATIONS.JIRA]: 'jira-software-cloud',
  [INTEGRATIONS.EXCEL]: 'microsoft-excel',
  [INTEGRATIONS.MONDAY]: 'monday',
  [INTEGRATIONS.MICROSOFT_TEAMS]: 'microsoft-teams',
  [INTEGRATIONS.OUTLOOK]: 'microsoft-outlook',
  [INTEGRATIONS.PDF_LEGACY]: 'pdf',
  [INTEGRATIONS.QUICKBOOKS]: 'quickbooks-online',
  [INTEGRATIONS.SENDINBLUE]: 'sendinblue',
  [INTEGRATIONS.TRELLO]: 'trello',
  [INTEGRATIONS.TWILIO]: 'twilio',
  [INTEGRATIONS.XERO]: 'xero',
  [INTEGRATIONS.ZOOM]: 'zoom',
  [INTEGRATIONS.MICROSOFT_DYNAMICS_BUSINESS_CENTRAL]:
    'dynamics365-business-central',
  [INTEGRATIONS.MICROSOFT_DYNAMICS_CRM]: 'dynamics365-crm',
  [INTEGRATIONS.GOOGLE_DRIVE]: 'google-drive',
  [INTEGRATIONS.ALGOLIA]: 'algolia',
  [INTEGRATIONS.ANTHROPIC]: 'anthropic',
  [INTEGRATIONS.CODA]: 'coda',
  [INTEGRATIONS.INSTAGRAM]: 'instagram',
  [INTEGRATIONS.SUPABASE]: 'supabase',
  [INTEGRATIONS.TODOIST]: 'todoist',
  [INTEGRATIONS.WEBFLOW]: 'webflow',
  [INTEGRATIONS.S3]: 's3',
  [INTEGRATIONS.GOOGLE_CLOUD_STORAGE]: 'google-cloud-storage',
  [INTEGRATIONS.LINEAR]: 'linear',
  [INTEGRATIONS.MAILERLITE]: 'mailerlite',
  [INTEGRATIONS.MAILGUN]: 'mailgun',
  [INTEGRATIONS.OMNISEND]: 'omnisend',
  [INTEGRATIONS.OPENAI]: 'openai',
  [INTEGRATIONS.PERPLEXITY]: 'perplexity',
  [INTEGRATIONS.PRODUCTBOARD]: 'productboard',
  [INTEGRATIONS.WOOCOMMERCE]: 'woocommerce',
  [INTEGRATIONS.APOLLO]: 'apollo-io',
  [INTEGRATIONS.CINC]: 'cinc',
  [INTEGRATIONS.FRESHMARKETER]: 'freshmarketer',
  [INTEGRATIONS.WORKIZ]: 'workiz'
};
