import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
  URL,
  CONTENT_TYPE
} from './utils';

export default {
  getPanels: (token: string, active?: boolean) => {
    let params = {};
    if (active !== undefined) params = { active };
    return httpGet({ token, url: URL.PANELS, params });
  },
  createPanel: (token: string, data: any) => {
    return httpPost({
      token,
      url: URL.PANELS,
      body: data,
      invalid: [URL.THEMES, URL.INTEGRATIONS, URL.IMAGE_UPLOAD, URL.SERVARS]
    });
  },
  editPanel: (token: string, data: any) => {
    const { panelId, ...newPanelData } = data;
    return httpPatch({
      token,
      baseUrl: URL.PANELS,
      path: `${panelId}/`,
      body: newPanelData,
      invalid: [`${URL.PANEL_STEPS}${panelId}/`, URL.THEMES]
    });
  },
  deletePanel: (token: string, { panelId }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.PANELS,
      path: `${panelId}/`,
      invalid: [
        `${URL.PANEL_STEPS}${panelId}/`,
        URL.SERVARS,
        URL.INTEGRATIONS,
        `${URL.ACCOUNT}organization/`
      ]
    });
  },
  getPanelStep: (token: string, { panelId }: any) => {
    return httpGet({
      token,
      url: `${URL.PANEL_STEPS}${panelId}/`
    });
  },
  getPanelCSVData: (
    token: string,
    { panelId, primary = true, startDate, endDate, onlyCompleted }: any
  ) => {
    return httpGet({
      token,
      url: `${URL.PANELS}${panelId}/fuser_csv_data/?primary_environment=${primary}&start_date=${startDate}&end_date=${endDate}&only_completed=${onlyCompleted}`,
      cachable: false
    });
  },
  getPanelPdf: (token: string, { panelId, fuserId }: any) => {
    return httpGet({
      token,
      url: `${URL.PANELS}${panelId}/print/?fuser=${fuserId}&print_format=pdf`,
      cachable: false
    });
  },
  getPanelLogicRule: (token: string, { panelId }: any) => {
    return httpGet({
      token,
      url: `${URL.PANEL_RULES}${panelId}/`
    });
  },
  putPanelStepSnapshot: (token: string, data: any) => {
    const { panelId, body } = data;
    return httpPut({
      token,
      url: `${URL.PANEL_STEPS}${panelId}/snapshot/v4/`,
      body: body,
      invalid: [
        URL.THEMES,
        URL.PANEL_STEPS,
        URL.SERVARS,
        URL.INTEGRATIONS,
        URL.PANELS
      ]
    });
  },
  getPanelDelta: (token: string, { panelId, forceLoad }: any) => {
    return httpGet({
      token,
      url: `${URL.PANEL_STEPS}${panelId}/delta/`,
      forceLoad
    });
  },
  updatePanelDelta: (token: string, data: any) => {
    const { panelId, data: panelDelta } = data;
    return httpPatch({
      token,
      baseUrl: URL.PANEL_STEPS,
      path: `${panelId}/delta/`,
      body: panelDelta,
      cachableResponse: true
    });
  },
  deletePanelDelta: (token: string, { panelId, data }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.PANEL_STEPS,
      path: `${panelId}/delta/`,
      body: data
    });
  },
  inviteCollaborators: (
    token: string,
    { panelId, collaborators, data }: any
  ) => {
    return httpPost({
      token,
      url: `${URL.PANELS}${panelId}/collaborator/`,
      body: { collaborators, ...data }
    });
  },
  startCollaboratorSession: (token: string, panelId: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.PANELS}${panelId}/collaborator/session/`,
      body: data
    });
  },
  editMetaImage: (token: string, panelId: string, image?: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.PANELS}${panelId}/meta_image/`,
      contentType: CONTENT_TYPE.MULTIPART_FORM,
      body: { image }
    });
  }
};
