/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import {
  PDFCheckBox,
  PDFDocument,
  PDFDropdown,
  PDFOptionList,
  PDFRadioGroup,
  PDFSignature,
  PDFTextField
} from 'pdf-lib';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { PUBLISH_STATUS } from '../../redux/utils';
import { Button, ContextMenu } from '../../components/Core';
import useFeatheryRedux from '../../redux';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { EditIcon, TrashIcon } from '../../components/Icons';
import { useGlobalMouseDownToggle } from '../../components/Core/util';
import { PDFFieldMappingModal } from '../../components/Modals';
import { AnnotatedField, FillableField, Geometry } from './types';
import { SelectedFieldData } from '../../components/Modals/FieldSelectorWithModal/FieldSelector';
import { Resizable } from 're-resizable';
import { FIELDS } from './constants';
import classNames from 'classnames';
import SidePanel from '../../components/SidePanel';
import DocumentFieldPanel from '../../components/Panels/DocumentFieldPanel';
import DocumentNavigation from '../../components/NavBar/DocumentNavigation';
import styles from './styles.module.scss';
import EditorPanel from './EditorPanel';
import useFieldDrop from './useFieldDrop';
import useDocumentFieldDrag, {
  DocumentFieldDragOperation
} from '../../components/CustomDragLayer/hooks/useDocumentFieldDrag';
import useSize from '../../hooks/useSize';
import { defaultHidePage } from './EditorPanel/DefaultPanel';
import { getOS } from '../../utils/browserUtils';
import { useKeyboardListener } from '../../hooks/useKeyboardListener';
import { isInputOrTextAreaSelected } from '../../components/RenderingEngine/GridInGrid/components/utils';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;

const docFieldWidth = 150;
const docFieldHeight = 30;
const minFieldSize = 18;
const pageMargin = 20;
export const DISPLAY_PDF_PAGE_WIDTH = 700;

const CONTROL_KEY = getOS() === 'MacOS' ? 'Meta' : 'Control';

const COPY_SHORTCUT = `${CONTROL_KEY}+KeyC` as const;
const PASTE_SHORTCUT = `${CONTROL_KEY}+KeyV` as const;
const DELETE_KEYS = ['Backspace', 'Delete'] as const;

const KEYS = [
  [CONTROL_KEY, 'KeyC'],
  [CONTROL_KEY, 'KeyV'],
  'Escape',
  ...DELETE_KEYS
] as const;

function DocumentEditorPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const history = useHistory();

  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const hasDisplayFile = doc.display_file != null;
  const isPDF = doc.type === 'pdf';

  if (!isPDF && !hasDisplayFile) {
    history.push(`/documents/${documentId}/envelopes`);
  }

  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);
  const [isResizing, setResizing] = useState<boolean | string>(false);
  const [hidePages, setHidePages] = useState<Record<string, any>[]>(
    doc.hide_pages.length ? doc.hide_pages : [defaultHidePage()]
  );
  const [annotatedFields, setAnnotatedFields] = useState<
    Record<string, AnnotatedField>
  >({});
  const [numPages, setNumPages] = useState(0);

  const pageRef = useRef(null);
  const docEditorSize = useSize(pageRef);

  const clipboard = useRef<AnnotatedField | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const [showSubMenu, setShowSubMenu] = useGlobalMouseDownToggle([ref]);
  const [activeLocation, setActiveLocation] = useState({
    page: 0,
    x: 0,
    y: 0,
    pageX: 0,
    pageY: 0
  });

  const { onKeyup, onKeypress } = useKeyboardListener({
    keys: KEYS,
    ignore: () => {
      const isInputSelected = isInputOrTextAreaSelected();
      const isBodyFocused = (document as any).activeElement.nodeName === 'BODY';

      return isInputSelected || !isBodyFocused;
    },
    preventDefault: true
  } as any);

  // the file url frequently changes query params that do not effect contents
  // we cache it to avoid loading it multiple times unnecessarily
  const [cachedFile, setCachedFile] = useState<string | null>(() => {
    return doc.type === 'pdf' ? doc.file : doc.display_file;
  });
  useEffect(() => {
    const file = doc.type === 'pdf' ? doc.file : doc.display_file;
    if (!file) {
      return;
    }
    // ignore query parameters in the url
    const currentUrl = file.split('?')[0];
    const cachedUrl = cachedFile?.split('?')[0];
    if (currentUrl !== cachedUrl) {
      // the file source has changed, update the cache
      setCachedFile(file);
    }
  }, [doc.file, doc.display_file, cachedFile]);

  const docRef = useRef<HTMLDivElement>(null);
  const {
    editDocument,
    toasts: { addToast, addErrorToast, addInfoToast }
  } = useFeatheryRedux();

  const mapAnnotatedFields = (annotatedFields: AnnotatedField[]) => {
    // transform fields into a map
    const transformed: Record<string, AnnotatedField> = {};
    annotatedFields.forEach(
      (field: AnnotatedField) => (transformed[field.id] = field)
    );
    return transformed;
  };
  useEffect(() => {
    setAnnotatedFields(mapAnnotatedFields(doc.annotated_fields));
  }, [doc.id]);

  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields || []
  );
  const servars = useAppSelector((state) => state.fields.servars || []);

  const formFieldMap = useMemo(() => {
    const map: Record<string, any> = {};
    [...hiddenFields, ...servars].forEach((field) => {
      map[field.id] = field;
    });
    return map;
  }, [hiddenFields, servars]);

  const [showModal, setShowModal] = useState(false);
  const [activeAnnotatedField, setActiveAnnotatedField] =
    useState<AnnotatedField | null>(null);

  const currentField = useRef<AnnotatedField | null>(null);

  useEffect(() => {
    currentField.current = activeAnnotatedField;
  }, [activeAnnotatedField]);

  const [pdfNameFieldMap, setPdfNameFieldMap] = useState<
    Record<string, FillableField>
  >({});

  useEffect(() => {
    if (isPDF) {
      // get fillable fields mapped by name
      fetch(doc.file)
        .then((res) => res.arrayBuffer())
        .then((formPdfBytes) => PDFDocument.load(formPdfBytes))
        .then((pdfDoc) => {
          const form = pdfDoc.getForm();

          const nameFieldMap: Record<string, FillableField> = {};
          form.getFields().forEach((field) => {
            const widgets = field.acroField.getWidgets();

            widgets.forEach((widget) => {
              const pageNumber = pdfDoc
                .getPages()
                .findIndex((x) => x.ref === widget.P());

              let type = 'PDFButton';
              if (field instanceof PDFTextField) type = 'PDFTextField';
              else if (field instanceof PDFRadioGroup) type = 'PDFRadioGroup';
              else if (field instanceof PDFOptionList) type = 'PDFOptionList';
              else if (field instanceof PDFSignature) type = 'PDFSignature';
              else if (field instanceof PDFCheckBox) type = 'PDFCheckBox';
              else if (field instanceof PDFDropdown) type = 'PDFDropdown';

              // PDF buttons are not mappable
              if (type !== 'PDFButton') {
                const rect = widget.getRectangle();
                const options =
                  type === 'PDFRadioGroup' ||
                  type === 'PDFOptionList' ||
                  type === 'PDFDropdown'
                    ? (field as PDFRadioGroup).getOptions()
                    : undefined;
                const name = field.getName();
                nameFieldMap[name] = {
                  ...rect,
                  pageNumber,
                  type,
                  name,
                  options
                };
              }
            });
          });
          setPdfNameFieldMap(nameFieldMap);
        });
    }
  }, [doc.id]);

  const [pagesRendered, setPagesRendered] = useState(false);
  const pageRects: Geometry[] = useMemo(() => {
    const rects: any[] = [];
    [...(docRef.current?.children ?? [])].forEach((page) => {
      const p = page as HTMLElement;
      rects.push({
        x: p.offsetLeft,
        y: p.offsetTop,
        width: p.offsetWidth,
        height: p.offsetHeight
      });
    });
    return rects;
  }, [docRef.current, pagesRendered, docEditorSize]);

  const validateFieldMapping = (annotatedField: any) => {
    let error = '';
    if (annotatedField && annotatedField.form_field_id) {
      const field = formFieldMap[annotatedField.form_field_id];
      if (!field) return error;

      if (
        field.type !== 'checkbox' &&
        !annotatedField.form_field_option_value &&
        ['checkbox', 'radio'].includes(annotatedField.type)
      )
        error = 'Please select an option value';
      else if (
        !annotatedField.form_field_child_item &&
        field?.type === 'matrix'
      )
        error = 'Please select a question to map';
      else if (
        annotatedField.form_field_repeating_index !== null &&
        annotatedField.form_field_repeating_index < 0
      )
        error = 'Repeat number must be greater than 0';
    }
    return error;
  };

  const submitButton = (
    <div style={{ display: 'flex' }}>
      <Button.Publish
        status={publishStatus}
        onPublish={async (e: any) => {
          e.preventDefault();

          setPublishStatus(PUBLISH_STATUS.LOADING);
          try {
            // validate the mapping for each field and roll-back any that
            // are invalid
            const oldAnnotatedFields = mapAnnotatedFields(doc.annotated_fields);
            const annotatedFieldsToSave = Object.values(annotatedFields).map(
              (annotatedField) => {
                const error = validateFieldMapping(annotatedField);
                if (error) {
                  // roll-back to the old annotated field's mapping
                  const oldAnnotatedField =
                    oldAnnotatedFields[annotatedField.id];
                  return {
                    ...annotatedField,
                    form_field_id: oldAnnotatedField.form_field_id,
                    form_field_type: oldAnnotatedField.form_field_type,
                    form_field_option_value:
                      oldAnnotatedField.form_field_option_value,
                    form_field_child_item:
                      oldAnnotatedField.form_field_child_item,
                    form_field_repeating_index:
                      oldAnnotatedField.form_field_repeating_index
                  };
                }
                return annotatedField;
              }
            );

            await editDocument({
              documentId,
              annotated_fields: annotatedFieldsToSave,
              hide_pages: hidePages.filter(
                (hidePage) => hidePage.field_type && hidePage.pages.length > 0
              )
            });
            setPublishStatus(PUBLISH_STATUS.FULFILLED);
            addInfoToast('Your document has been updated.');
          } catch (error: any) {
            addErrorToast({ title: error.message });
            setPublishStatus(PUBLISH_STATUS.ACTIVE);
          }
        }}
        partial
        saveFlag
      />
      <Button.Share type='document' />
    </div>
  );

  const updateHidePages = (newHidePages: any[]) => {
    setHidePages(newHidePages);
    setPublishStatus(PUBLISH_STATUS.ACTIVE);
  };

  const updateFields = (transform: any) => {
    setAnnotatedFields((fields) => produce(fields, transform));
    setPublishStatus(PUBLISH_STATUS.ACTIVE);
  };

  const addOrUpdateField = (
    type: string,
    _activeLocation: any = null,
    fieldId: string | null = null
  ) => {
    const location = _activeLocation ?? activeLocation;

    const existingField = fieldId
      ? annotatedFields[fieldId]
      : {
          id: uuidv4(),
          width: _activeLocation?.width ?? docFieldWidth,
          height: _activeLocation?.height ?? docFieldHeight,
          required: true
        };
    const field: any = {
      ...existingField,
      page: location.page,
      x: Math.max(location.x, 0),
      y: Math.max(location.y, 0),
      type
    };

    updateFields((draft: any) => {
      draft[field.id] = {
        ...field
      };
    });

    return field;
  };

  const pasteField = () => {
    const field = clipboard.current;
    if (!field) return;
    const locationField = currentField.current || field;

    const location = {
      page: locationField.page,
      x: locationField.x,
      y: locationField.y + locationField.height + 8
    };
    const newField: any = {
      ...field,
      ...location,
      id: uuidv4()
    };

    updateFields((draft: any) => {
      draft[newField.id] = {
        ...newField
      };
    });

    return newField;
  };

  const editField = ({
    id,
    ...rest
  }: Pick<AnnotatedField, 'id'> & Partial<AnnotatedField>) => {
    const newField = {
      ...annotatedFields[id],
      ...rest
    };

    newField.width = Math.max(newField.width, minFieldSize);
    newField.height = Math.max(newField.height, minFieldSize);

    updateFields((draft: any) => {
      draft[id] = {
        ...newField
      };
    });

    // Reset the active annotated field to update the data within it
    if (newField.id === activeAnnotatedField?.id) {
      setActiveAnnotatedField(newField);
    }
  };

  const mapField = (
    annotatedField: AnnotatedField | null,
    fieldId: string | undefined,
    fieldType: '' | 'servar' | 'hidden'
  ) => {
    if (annotatedField) {
      const newField = {
        ...annotatedField,
        form_field_id: fieldId,
        form_field_type: fieldType
      };

      updateFields((draft: any) => {
        const id = annotatedField.id;
        draft[id] = {
          ...newField
        };
      });

      // Reset the active annotated field to update the data within it
      if (newField.id === activeAnnotatedField?.id) {
        setActiveAnnotatedField(newField);
      }
    }
  };

  const contextMenuHandler = (
    e: any,
    page: number,
    field: AnnotatedField | null = null
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setActiveLocation({
      page,
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      pageX: e.clientX,
      pageY: e.clientY
    });
    setActiveAnnotatedField(field);

    // If right-clicked, open the menu
    if (e.button === 2) setShowSubMenu(true);
  };

  const dropRef = useFieldDrop({
    pageRects,
    annotatedFields,
    addOrUpdateField,
    setActiveAnnotatedField
  });

  const AnnotatedFieldComponent = ({
    field,
    page,
    mappingError,
    mappedFormFieldKey,
    changeable
  }: {
    field: AnnotatedField;
    page: number;
    mappingError: string;
    mappedFormFieldKey: string;
    changeable: boolean;
  }) => {
    const isActive = activeAnnotatedField?.id === field.id;
    const Icon = FIELDS[field.type]?.Icon;
    const label = mappingError || mappedFormFieldKey || (Icon ? <Icon /> : '');
    const [dragState, drag] = useDocumentFieldDrag(() => ({
      operation: DocumentFieldDragOperation.Move,
      type: field.type,
      label,
      id: field.id,
      location: {
        x: field.x,
        y: field.y
      },
      opts: {
        preview: {
          width: field.width,
          height: field.height,
          className: classNames({
            [styles.filled]: field.form_field_id,
            [styles.changeable]: changeable,
            [styles.error]: mappingError
          })
        },
        onCanDrag: () => {
          return changeable && !isResizing;
        }
      }
    }));
    // Don't render the field if it's being dragged
    if (dragState.isDragging) {
      return <div ref={drag}></div>;
    }

    return (
      <div
        ref={drag}
        style={isResizing ? {} : { width: field.width, height: field.height }}
        className={classNames(styles.fillableField, {
          [styles.filled]: field.form_field_id,
          [styles.changeable]: changeable,
          [styles.error]: mappingError,
          [styles.selected]: isActive
        })}
        onContextMenu={(e) => {
          contextMenuHandler(e, page, field);
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          setActiveAnnotatedField(field);
        }}
      >
        <div className={styles.fillableFieldLabel}>{label}</div>
        {changeable && isActive && (
          <div className={styles.handleContainer}>
            <div className={styles.dragHandle} style={{ top: -5, left: -5 }} />
            <div className={styles.dragHandle} style={{ top: -5, right: -5 }} />
            <div
              className={styles.dragHandle}
              style={{ bottom: -5, left: -5 }}
            />
            <div
              className={styles.dragHandle}
              style={{ bottom: -5, right: -5 }}
            />
          </div>
        )}
      </div>
    );
  };

  const determineResizePosition = (
    startX: number,
    startY: number,
    direction: string,
    delta: any
  ) => {
    const dir = direction.toLocaleLowerCase();
    // make resize on the top and left work as the user would expect
    const x = dir.includes('left') ? startX - delta.width : startX;
    const y = dir.includes('top') ? startY - delta.height : startY;

    return { x, y };
  };

  const deleteActiveField = () => {
    const field = currentField.current;
    if (field) {
      updateFields((draft: any) => {
        delete draft[field.id];
      });
      setActiveAnnotatedField(null);
    }
  };

  useEffect(() => {
    if (onKeypress) {
      onKeypress(({ shortcut }: any) => {
        if (shortcut === COPY_SHORTCUT) {
          clipboard.current = currentField.current;
        } else if (shortcut === PASTE_SHORTCUT) {
          if (clipboard.current) {
            const newField = pasteField();
            setActiveAnnotatedField(newField);
          }
        }
      });
    }
  }, [onKeypress]);

  useEffect(() => {
    if (onKeyup) {
      onKeyup(({ shortcut, isClicked }: any) => {
        if (shortcut === 'Escape' && isClicked) {
          setActiveAnnotatedField(null);
        } else if (DELETE_KEYS.includes(shortcut) && isClicked) {
          deleteActiveField();
        }
      });
    }
  }, [onKeyup]);

  return (
    <>
      <DocumentNavigation activeItem='editor' submitButton={submitButton} />
      <div
        ref={pageRef}
        style={{
          width: '100vw',
          display: 'flex',
          overflowX: 'hidden',
          flex: 1
        }}
      >
        {(isPDF || hasDisplayFile) && (
          <>
            {isPDF && (
              <SidePanel
                position='left'
                classNames={{ sidePanel: styles.sidePanel }}
              >
                <DocumentFieldPanel fields={FIELDS} />
              </SidePanel>
            )}
            <div
              ref={dropRef}
              id='document-canvas'
              style={{
                width: '100%',
                height: 'calc(100vh - 55px)',
                position: 'relative',
                paddingTop: `${pageMargin}px`,
                boxSizing: 'border-box',
                overflowY: 'scroll'
              }}
              onClick={() => setActiveAnnotatedField(null)}
            >
              <ContextMenu
                ref={ref}
                position={{ x: activeLocation.pageX, y: activeLocation.pageY }}
                show={isPDF && showSubMenu}
                close={() => setShowSubMenu(false)}
                actions={[
                  {
                    onMouseDown: () => setShowModal(true),
                    show: () => activeAnnotatedField !== null,
                    Icon: EditIcon,
                    title: 'Attach Form Field'
                  },
                  ...Object.values(FIELDS).map((field) => ({
                    onMouseDown: () => addOrUpdateField(field.type),
                    show: () => activeAnnotatedField === null,
                    Icon: field.Icon,
                    title: field.label
                  })),
                  {
                    onMouseDown: () => {
                      if (activeAnnotatedField) {
                        updateFields((draft: any) => {
                          delete draft[activeAnnotatedField.id];
                        });
                        setActiveAnnotatedField(null);
                      }
                    },
                    // only allowed to delete annotated fields that are derived from a fillable pdf field
                    show: () =>
                      activeAnnotatedField !== null &&
                      !activeAnnotatedField.pdf_field_id,
                    Icon: TrashIcon,
                    title: 'Delete Field'
                  }
                ]}
              />
              {cachedFile && (
                <Document
                  inputRef={docRef}
                  file={cachedFile}
                  className={styles.pdfDocument}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  {new Array(numPages).fill(0).map((_, i) => (
                    <Page
                      key={i}
                      pageNumber={i + 1}
                      width={DISPLAY_PDF_PAGE_WIDTH}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      className={styles.pdfPage}
                      onRenderSuccess={() => setPagesRendered(true)}
                      onContextMenu={(e) => contextMenuHandler(e, i)}
                      onClick={(e) => contextMenuHandler(e, i)}
                    >
                      {isPDF &&
                        Object.values(annotatedFields)
                          .filter((field) => field.page === i)
                          .map((field) => {
                            const mappedFormFieldKey: string =
                              formFieldMap[field.form_field_id ?? '']?.key ??
                              '';
                            let mappingError = '';
                            if (
                              field.form_field_id &&
                              !formFieldMap[field.form_field_id]
                            )
                              mappingError = 'Mapping Error'; // form field no longer exists

                            if (!field.pdf_field_id)
                              // Only pure annotated fields can be resized and moved.
                              // These are the ones not derived from a fillable pdf field.
                              return (
                                <Resizable
                                  key={`${field.id}-${field.width}-${field.height}`}
                                  minWidth={minFieldSize}
                                  minHeight={minFieldSize}
                                  enable={
                                    activeAnnotatedField?.id === field.id &&
                                    undefined
                                  }
                                  defaultSize={{
                                    width: field.width,
                                    height: field.height
                                  }}
                                  style={{
                                    left: field.x,
                                    top: field.y,
                                    position: 'absolute' // important to override here
                                  }}
                                  handleClasses={{
                                    top: styles.dragZone,
                                    bottom: styles.dragZone,
                                    right: styles.dragZone,
                                    left: styles.dragZone,
                                    topRight: styles.dragZone,
                                    topLeft: styles.dragZone,
                                    bottomRight: styles.dragZone,
                                    bottomLeft: styles.dragZone
                                  }}
                                  handleStyles={{
                                    topRight: {
                                      height: 10,
                                      width: 10,
                                      top: -5,
                                      right: -5
                                    },
                                    topLeft: {
                                      height: 10,
                                      width: 10,
                                      top: -5,
                                      left: -5
                                    },
                                    bottomRight: {
                                      height: 10,
                                      width: 10,
                                      bottom: -5,
                                      right: -5
                                    },
                                    bottomLeft: {
                                      height: 10,
                                      width: 10,
                                      bottom: -5,
                                      left: -5
                                    }
                                  }}
                                  lockAspectRatio={
                                    field.type === 'checkbox' ||
                                    field.type === 'radio'
                                      ? 1
                                      : undefined
                                  }
                                  onResize={(_e, direction, ref, delta) => {
                                    setResizing(true);
                                    setActiveAnnotatedField(field);
                                    const { x, y } = determineResizePosition(
                                      field.x,
                                      field.y,
                                      direction,
                                      delta
                                    );
                                    // user sees smooth resizing
                                    ref.style.left = `${x}px`;
                                    ref.style.top = `${y}px`;
                                  }}
                                  onResizeStop={(
                                    _e,
                                    direction,
                                    _ref,
                                    delta
                                  ) => {
                                    // user done resizing so update the field
                                    const { x, y } = determineResizePosition(
                                      field.x,
                                      field.y,
                                      direction,
                                      delta
                                    );
                                    editField({
                                      id: field.id,
                                      x,
                                      y,
                                      width: field.width + delta.width,
                                      height: field.height + delta.height
                                    });
                                    setResizing(false);
                                  }}
                                >
                                  <AnnotatedFieldComponent
                                    field={field}
                                    page={i}
                                    mappingError={mappingError}
                                    mappedFormFieldKey={mappedFormFieldKey}
                                    changeable
                                  />
                                </Resizable>
                              );
                            // not changeable type so therefore not resizable
                            else
                              return (
                                <div
                                  key={field.id}
                                  className={styles.fieldContainer}
                                  style={{
                                    left: field.x,
                                    top: field.y,
                                    width: field.width,
                                    height: field.height
                                  }}
                                >
                                  <AnnotatedFieldComponent
                                    field={field}
                                    page={i}
                                    mappingError={mappingError}
                                    mappedFormFieldKey={mappedFormFieldKey}
                                    changeable={false}
                                  />
                                </div>
                              );
                          })}
                    </Page>
                  ))}
                </Document>
              )}
            </div>
            {isPDF && (
              <SidePanel
                position='right'
                classNames={{
                  content: (hidden) =>
                    hidden ? styles.rightSideBarHidden : '',
                  sidePanel: styles.sidePanel
                }}
              >
                <EditorPanel
                  doc={doc}
                  activeAnnotatedField={activeAnnotatedField}
                  setActiveAnnotatedField={setActiveAnnotatedField}
                  hidePages={hidePages}
                  setHidePages={updateHidePages}
                  pdfField={
                    pdfNameFieldMap[activeAnnotatedField?.pdf_field_id ?? '']
                  }
                  setShowModal={setShowModal}
                  formFieldMap={formFieldMap}
                  validateFieldMapping={validateFieldMapping}
                  editField={editField}
                  updateFields={updateFields}
                  addToast={addToast}
                  numPages={numPages}
                />
              </SidePanel>
            )}
          </>
        )}
      </div>

      {isPDF && activeAnnotatedField && (
        <PDFFieldMappingModal
          show={showModal}
          setShow={setShowModal}
          selectedFieldId={activeAnnotatedField?.form_field_id ?? ''}
          selectedFieldType={activeAnnotatedField?.form_field_type ?? 'servar'}
          annotatedField={activeAnnotatedField}
          onSelectField={({ selectId, selectType }: SelectedFieldData) => {
            mapField(activeAnnotatedField, selectId, selectType);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

export default memo(DocumentEditorPage);
