import '../../../../style/dialog-form.css';
import { Neutral, Positive } from '../../../Core/Button';
import { DropdownField, TextField } from '../../../Core';
import Label from '../../../Dialog/Label';
import dialogStyles from '../../../Dialog/styles.module.scss';
import { useAppSelector } from '../../../../hooks';
import testIds from '../../../../utils/testIds';

export default function CreateFeatheryForm({
  isFinServTemplate,
  changeStep,
  formData,
  handleChange,
  error,
  lockOutFlag,
  lockoutFunction
}: any) {
  const themes = useAppSelector((s) => s.themes.themes);
  const finserveTemplates = useAppSelector(
    (s) => s.themes.templates.finserve_templates
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return lockoutFunction();
      }}
    >
      {isFinServTemplate && (
        <>
          <Label>Template</Label>
          <DropdownField
            className={dialogStyles.input}
            selected={formData.finserve_template}
            placeholder='Select'
            onChange={(event: any) =>
              handleChange({ finserve_template: event.target.value })
            }
            options={finserveTemplates.map((template) => ({
              display: `${template.name} (${template.quik_form_id})`,
              value: template.name
            }))}
          />
        </>
      )}
      <Label>Name</Label>
      <TextField
        autoFocus
        value={formData.key}
        placeholder='My Form'
        onComplete={(key: any) => handleChange({ key })}
        className={dialogStyles.input}
      />
      {!isFinServTemplate && (
        <>
          <Label>Theme</Label>
          <DropdownField
            disabled={Boolean(formData.template)}
            className={dialogStyles.input}
            selected={formData.theme}
            onChange={(event: any) =>
              handleChange({ theme: event.target.value })
            }
            options={[
              {
                value: '',
                display: formData.template
                  ? `${formData.template} Theme`
                  : '+ Create New Theme'
              },
              ...Object.values(themes).map(({ id, key }) => ({
                value: id,
                display: key
              }))
            ]}
          />
        </>
      )}
      <div className='dialog-form-action text-center'>
        <Neutral title='Back' onClick={() => changeStep(1)} />
        <Positive
          data-testid={testIds.createFormDialogSubmit}
          title='Create'
          lockoutOverride={lockOutFlag}
        />
      </div>
      {error && <div>{error}</div>}
    </form>
  );
}
