import { Salesforce } from '../../Logos';

const SALESFORCE_TEMPLATE = {
  id: 'salesforce',
  name: 'Salesforce',
  logo: <Salesforce />,
  token: 'salesforce',
  createConnector: (credential: any) => ({
    name: 'Salesforce Prefill',
    template: 'salesforce',
    url: `${credential.data.userCustomDomainUrl}/services/data/v50.0/query`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer {{salesforce_token}}'
    },
    body: '',
    params: {
      q: "SELECT Id, Name, AccountId FROM Contact WHERE Email='{{SalesforceEmail}}' LIMIT 1"
    },
    tokens: { salesforce: credential.credentialKey },
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  })
};

const CUSTOM_TEMPLATE = {
  id: 'custom',
  name: 'Custom Config',
  logo: null,
  token: null,
  createConnector: () => ({
    name: '',
    template: '',
    url: '',
    method: 'GET',
    headers: {},
    body: '',
    params: {},
    tokens: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  })
};

export const TEMPLATES = {
  custom: CUSTOM_TEMPLATE,
  salesforce: SALESFORCE_TEMPLATE
};

export const GUIDE_TEXT = {
  salesforce: (
    <div>
      <p className='tw-font-medium'>
        Pre-fill your form with Salesforce object data.
      </p>
      <p>
        Set a{' '}
        <a
          href='https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm'
          target='_blank'
          rel='noreferrer'
        >
          SOQL query
        </a>{' '}
        below to define what Salesforce records to pull. Add Feathery field
        values to the query by including the field ID wrapped in double curly
        braces and quotes within a WHERE clause, such as{' '}
        <pre
          style={{
            display: 'inline',
            padding: '2px 3px',
            background: '#ededed',
            borderRadius: 4
          }}
        >
          {"WHERE Email = '{{ExampleEmailField}}'"}
        </pre>
        .
      </p>
    </div>
  )
};
