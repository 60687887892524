/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrashIcon } from '../Icons';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';
import { useAppSelector } from '../../hooks';
import { TextField } from '../Core';

function DeleteFormModal({ show, setShow, formId, name }: any) {
  const {
    deletePanel,
    deleteTheme,
    getFields,
    getIntegrations,
    getOrganization,
    toasts: { addToast }
  } = useFeatheryRedux();
  const history = useHistory();
  const [step, setStep] = useState<0 | 1>(0);
  const [confirmText, setConfirmText] = useState('');

  const { formId: urlFormId } = useParams<{ formId: string }>();
  formId = formId || urlFormId;
  const panelsData = useAppSelector((state) => state.panels.panels);
  const panel = panelsData[formId];
  const themeUsedOnce =
    Object.values(panelsData).filter(
      (iterPanel) => iterPanel.theme === panel?.theme
    ).length <= 1;

  const themes = useAppSelector((state) => state.themes.themes);
  const theme = themes[panel?.theme ?? ''];

  const account = useAppSelector((state) => state.accounts.account);
  const formDeletePermission =
    account.role === 'admin' || account.permission_delete_forms;

  // deleteThemeLockout - locks out both buttons
  const [lockoutOverride, setLockoutOverride] = useState(false);

  useEffect(() => {
    if (!show) setStep(0);
  }, [show]);

  const performDelete = async (themeDeletion = false) => {
    await deletePanel({ panelId: panel.id });
    if (themeDeletion) {
      await deleteTheme({ themeId: theme.id });
      await deletePart2();
    } else await deletePart2();
    setConfirmText('');
  };

  const deletePart2 = async () => {
    // Only redirect to /forms if not already there
    if (urlFormId) history.push('/forms');
    await Promise.all([getFields(), getIntegrations(), getOrganization()]);
    addToast({
      icon: <TrashIcon />,
      text: (
        <>
          <strong>{panel.key}</strong> was deleted.
        </>
      )
    });
    if (!urlFormId) setShow(false);
  };

  const component =
    step === 0 ? (
      <>
        <p style={{ padding: '0 70px', margin: '20px 0 20px 0' }}>
          You will lose access to the form and its submissions forever. Confirm
          by typing the form name below.
        </p>
        <TextField
          placeholder={panel?.key}
          value={confirmText}
          onChange={(text: string) => setConfirmText(text)}
        />
        <div className='dialog-form-action'>
          <Neutral title='Cancel' onClick={() => setShow(false)} />
          <Negative
            title='Delete Forever'
            onClick={async (event: any) => {
              event.preventDefault();
              if (themeUsedOnce) setStep(1);
              else await performDelete();
            }}
            disabled={confirmText !== panel?.key || !formDeletePermission}
          />
        </div>
      </>
    ) : (
      <div className='dialog-form-action'>
        <Neutral
          title='No'
          lockoutOverride={lockoutOverride}
          setLockoutOverride={setLockoutOverride}
          onClick={() => performDelete()}
        />
        <Negative
          title='Yes, Delete Theme'
          lockoutOverride={lockoutOverride}
          setLockoutOverride={setLockoutOverride}
          onClick={() => performDelete(true)}
        />
      </div>
    );

  const themeName = theme ? theme.key : '';
  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title={
        step === 0
          ? `Are you sure you want to delete this form?`
          : `Do you also want to delete its theme ${themeName}?`
      }
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      {component}
    </Dialog>
  );
}

export default memo(DeleteFormModal);
