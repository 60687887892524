import { memo, useState } from 'react';
import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { PaymentMethod, PlanDetails } from '../PricingPlans';

import styles from './styles.module.scss';
import { formatMoneyUSD } from '../../utils/format';
import { Neutral, Positive } from '../Core/Button';
import { WarningIcon } from '../Icons';
import { useAppSelector } from '../../hooks';
import mixpanel from 'mixpanel-browser';
import { IS_PROD_FE } from '../../api/utils';

function ChangePricingPlanModal({
  currentPlan = {},
  plan = {},
  show = false,
  setShow = (_: boolean) => {}
}: {
  currentPlan: Record<string, any>;
  plan: Record<string, any>;
  show?: boolean;
  setShow?: any;
}) {
  const org = useAppSelector((state) => state.accounts.organization);
  const [cardEntry, setCardEntry] = useState(false);

  const {
    editOrganization,
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const [error, setError] = useState('');

  const title = `${
    currentPlan.tier <= plan.tier ? 'Upgrade' : 'Downgrade'
  } to ${plan.title} now`;

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon
      title={title}
      size='sm'
    >
      <div className={styles.planUpgrade}>
        <div className={styles.title}>{`The ${plan.title} plan includes:`}</div>

        <PlanDetails plan={plan} />

        {plan.tier === 0 && (
          <div className={styles.warning}>
            <WarningIcon width={16} color='#d6504a' />
            Any premium integrations will be disabled.
          </div>
        )}
        {plan.forms < currentPlan.forms && (
          <div className={styles.warning}>
            <WarningIcon width={16} color='#d6504a' />
            On the new plan you may be required to turn forms off.
          </div>
        )}

        {plan.cost !== null && (
          <>
            <div className={styles.monthlyRateText}>
              Your monthly rate will now be:
            </div>
            <div className={styles.planCost}>{formatMoneyUSD(plan.cost)}</div>
            <div className={styles.prorated}>
              {plan.tier > currentPlan.tier
                ? 'If upgrading mid cycle, your rate will be prorated.'
                : 'Your new plan is effective (and charged) on your next bill date.'}
            </div>
          </>
        )}
      </div>
      {Boolean(plan.cost) && !org?.card_data && (
        <div className={styles.paymentMethod}>
          <label className={styles.label}>
            Payment Details
            {cardEntry && (
              <span className={styles.validation}>
                * Credit card entry required.
              </span>
            )}
          </label>
          <PaymentMethod showCardEntry={cardEntry} />
        </div>
      )}
      <div
        className='dialog-form-action'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Neutral title='Cancel' onClick={() => setShow(false)} />
        <Positive
          title={
            currentPlan.tier <= plan.tier
              ? 'Confirm Upgrade'
              : 'Confirm Downgrade'
          }
          onClick={async (event: any) => {
            event.preventDefault();
            if (plan.cost && !org?.card_data) {
              setCardEntry(true);
            } else {
              try {
                await editOrganization({ tier: plan.tier });
                addInfoToast('Pricing plan updated');
                if (IS_PROD_FE) {
                  mixpanel.track('Upgrade', { plan: plan.tier });
                  // @ts-ignore
                  const tapfiliate = window.tap;
                  // Track conversion for affiliate marketing if present
                  if (tapfiliate && org?.stripe_id)
                    tapfiliate.trial(org.stripe_id);
                }
                setShow(false);
              } catch (error) {
                // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                setError(error.message);
              }
            }
          }}
        />
      </div>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(ChangePricingPlanModal);
