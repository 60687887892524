/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useFeatheryRedux from '../../../redux';

import '../../../style/dialog-form.css';
import { deepEquals, objectApply } from '../../../utils/core';
import Dialog from '../../Dialog';
import { uniqifyKey } from '../../../utils/format';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useAppSelector } from '../../../hooks';
import TemplatePicker from './components/TemplatePicker';
import ModalHeader from './components/ModalHeader';
import styles from './styles.module.scss';
import CreateFeatheryForm from './components/CreateFeatheryForm';

const DEFAULT_FORM_STATE = {
  key: '',
  theme: '',
  template: '',
  finserve_template: ''
};

export default function PanelCreateModal({
  step,
  changeStep,
  newOrg,
  folder
}: any) {
  const history = useHistory();
  const {
    createPanel,
    getFields,
    getImages,
    getThemes,
    getPanelThemeAssetUse,
    getIntegrations,
    editOrganization,
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const isOpen = step !== 0;

  const themes = useAppSelector((s) => s.themes.themes);
  const panelKeys = useAppSelector(
    (state) => Object.values(state.panels.panels).map((p) => p.key),
    deepEquals
  );

  const [error, setError] = useState('');
  const [formData, setFormData] = useState(DEFAULT_FORM_STATE);

  const close = () => {
    if (newOrg) editOrganization({ new: false });
    changeStep(0);
    setError('');
    setFormData(DEFAULT_FORM_STATE);
  };

  const handleChange = (newData: any) => {
    setFormData((formData) => ({
      ...formData,
      ...newData
    }));
  };

  const handleSubmit = useCallback(async () => {
    const { key, theme } = formData;
    if (key.trim().length < 1) {
      setError('You need to enter a form name');
      return;
    }

    const payload = objectApply(formData, {
      key,
      folder: folder ?? '',
      theme: theme || null
    });
    try {
      const response = await createPanel(objectApply(formData, payload));
      // Creating the panel may have generated a new theme or copied images that needs to be fetched
      await Promise.all([
        getThemes(),
        getImages(),
        getPanelThemeAssetUse({ cached: false }),
        getIntegrations(),
        getFields(),
        getFields({ hidden: true })
      ]);
      close();
      history.push(`/forms/${response.id}`);
      if (!response.active) {
        // a form is created in an inactive state only when a plan limit has been hit
        addInfoToast(
          "Your created form is inactive because you're at your account limit."
        );
      }
    } catch (e: any) {
      setError(
        'duplicate-key-error' === e.code
          ? 'Form name already being used'
          : e.message
      );
    }
  }, [formData]);

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(handleSubmit);

  const getFormKey = (isFinServ: boolean, key: string) => {
    if (isFinServ) key = 'New Form';
    return key ? uniqifyKey(key, panelKeys) : '';
  };

  let content;
  if (step === 1) {
    const onSelect = (key: any) => {
      if (key === 'blank') key = '';

      const isFinServ = key === 'Financial Services Form Library';

      const themeList = Object.values(themes).filter(
        (theme) => theme.key !== 'Default Guide Theme'
      );
      const newTheme =
        key === '' && themeList.length ? (themeList as any)[0].id : '';
      handleChange({
        template: isFinServ ? '' : key,
        theme: newTheme,
        key: getFormKey(isFinServ, key)
      });

      changeStep(isFinServ ? 1.5 : 2);
    };
    content = <TemplatePicker onSelect={onSelect} />;
  } else {
    content = (
      <CreateFeatheryForm
        isFinServTemplate={step === 1.5}
        changeStep={changeStep}
        formData={formData}
        handleChange={handleChange}
        error={error}
        lockOutFlag={lockOutFlag}
        lockoutFunction={lockoutFunction}
      />
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title='Create New Form'
      header={
        step === 1
          ? (title: string) => {
              return (
                <div className={styles.dialogHeader}>
                  <h2>{title}</h2>
                  <ModalHeader />
                </div>
              );
            }
          : undefined
      }
      size={[1.5, 2].includes(step) ? 'xsm' : 'flush'}
    >
      {content}
    </Dialog>
  );
}
