import { addDefaultReducers, OPERATION, parseAndThrowError } from './utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from '../pages/APIConnectorPage/types';

const IMPORTANT_FONT_OPTIONS = [
  {
    value:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    display: 'System Default'
  }
];

const NORMAL_FONT_OPTIONS = [
  { value: 'Arial, sans-serif', display: 'Arial' },
  { value: 'Axiforma, sans-serif', display: 'Axiforma' },
  { value: 'Basis Grotesque, sans-serif', display: 'Basis Grotesque' },
  {
    value: 'Basis Grotesque (Off-White), sans-serif',
    display: 'Basis Grotesque (Off-White)'
  },
  { value: 'calistoga, serif', display: 'Calistoga' },
  { value: 'adobe-caslon-pro, serif', display: 'Caslon' },
  { value: 'Chronicle Display, serif', display: 'Chronicle Display' },
  { value: "'Circular std', sans-serif", display: 'Circular Std' },
  { value: 'Domaine Sans Text, sans-serif', display: 'Domaine Sans Text' },
  { value: "'FF Sero Std', sans-serif", display: 'FF Sero Std' },
  { value: 'Graphik, sans-serif', display: 'Graphik' },
  { value: 'PolySans, sans-serif', display: 'PolySans' },
  { value: 'PrimaryFont, sans-serif', display: 'Primary' },
  { value: 'Sansation, sans-serif', display: 'Sansation' }
];

const createIntegration = createAsyncThunk(
  'integrations/createIntegration',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createIntegration(token, data);

    const result = await response.json();
    if (response.status === 201) return result;
    else {
      parseAndThrowError(result, 'Could not create integration.');
    }
  }
);

const getIntegrations = createAsyncThunk(
  'integrations/getIntegrations',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getIntegrations(token);

    if (response.status === 404) {
      return {};
    } else if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not fetch integrations');
  }
);

const getCustomAPIs = createAsyncThunk(
  'integrations/getCustomAPIs',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.getCustomAPIs(token, data);

    if (response.status === 404) {
      return {};
    } else if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not fetch API connectors');
  }
);

const createCustomAPI = createAsyncThunk(
  'integrations/createCustomAPI',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createCustomAPI(token, data);

    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not create API connector');
    }
  }
);

const editCustomAPI = createAsyncThunk(
  'integrations/editCustomAPI',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editCustomAPI(token, data);

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 204) {
      return;
    } else {
      throw new Error('Could not edit API connector');
    }
  }
);

const testCustomAPI = createAsyncThunk(
  'integrations/testCustomAPI',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.testCustomAPI(token, data);

    if (response.status >= 200 && response.status < 300) {
      return await response.json();
    } else if (response.status === 204) {
      return {};
    } else {
      throw new Error('Failed to get a response from the configured API.');
    }
  }
);

const editIntegration = createAsyncThunk(
  'integrations/editIntegration',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editIntegration(token, data);

    const result = await response.json();
    if (response.status === 200) return result;
    else parseAndThrowError(result, 'Could not edit integration.');
  }
);

const switchIntegrationProperty = createAsyncThunk(
  'integrations/switchIntegrationProperty',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.switchIntegrationProperty(token, data);

    const result = await response.json();
    if (response.status === 201) return result;
    else parseAndThrowError(result, 'Could not switch integration property.');
  }
);

const exchangeOAuthToken = createAsyncThunk(
  'integrations/exchangeOAuthToken',
  async ({ token, ...data }: { token: string }) => {
    let response = await FeatheryAPI.exchangeOAuthToken(token, data);

    if (response.status === 201) {
      response = await FeatheryAPI.getIntegrations(token);

      if (response.status === 404) {
        return {};
      } else if (response.status === 200) {
        return await response.json();
      }

      throw new Error('Could not fetch integrations');
    } else {
      throw new Error('Could not exchange code for oauth token');
    }
  }
);

const revokeOAuthToken = createAsyncThunk(
  'integrations/revokeOAuthToken',
  async ({ token, ...data }: { token: string }) => {
    let response = await FeatheryAPI.revokeOAuthToken(token, data);

    if (response.status === 204) {
      response = await FeatheryAPI.getIntegrations(token);

      if (response.status === 404) {
        return {};
      } else if (response.status === 200) {
        return await response.json();
      }

      throw new Error('Could not fetch integrations');
    } else {
      throw new Error('Could not revoke oauth token');
    }
  }
);

const getDynamicFonts = createAsyncThunk(
  'integrations/getDynamicFonts',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getDynamicFonts(token);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch dynamic fonts');
    }
  }
);

const uploadFont = createAsyncThunk(
  'integrations/uploadFont',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.uploadFont(token, data);

    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not upload font');
    }
  }
);

const getTranslations = createAsyncThunk(
  'integrations/getTranslations',
  async ({ token, panelId }: { token: string; panelId: string }) => {
    const response = await FeatheryAPI.getTranslations(token, panelId);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch translations');
    }
  }
);

const editTranslations = createAsyncThunk(
  'integrations/editTranslations',
  async ({
    token,
    panelId,
    translations
  }: {
    token: string;
    panelId: string;
    translations: Record<string, any>;
  }) => {
    const response = await FeatheryAPI.editTranslations(
      token,
      panelId,
      translations
    );

    const result = await response.json();
    if (response.status === 200) return result;
    else {
      parseAndThrowError(result, 'Could not edit translations.');
    }
  }
);

const fetchRolloutToken = createAsyncThunk(
  'integrations/fetchRolloutToken',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.fetchRolloutToken(token);

    const result = await response.json();
    if (response.status === 200) return result;
    else {
      parseAndThrowError(result, 'Could not fetch Rollout token.');
    }
  }
);

const fetchQuikFields = createAsyncThunk(
  'integrations/fetchQuikFields',
  async ({ integMeta, formId }: { integMeta: any; formId: string }) => {
    const encodedCredentials = new URLSearchParams({
      username: integMeta.username,
      password: integMeta.password
    });
    const tokenRes = await fetch(
      'https://websvcs.quikforms.com/rest_authentication/token',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `grant_type=password&${encodedCredentials.toString()}`
      }
    ).then((res) => res.json());

    const url = new URL(
      'https://websvcs.quikforms.com/rest/qfem/v2000/forms/fields'
    );
    url.searchParams.append('FormIds', formId);

    const fieldRes = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenRes.access_token}`
      }
    }).then((response) => response.json());

    return fieldRes.ResultData;
  }
);

const updateFontOptions = (state: any) => {
  const fontOptions = [...NORMAL_FONT_OPTIONS, ...state.defaultFonts].sort(
    (font1, font2) => font1.display.localeCompare(font2.display)
  );
  state.fontOptions = [
    ...IMPORTANT_FONT_OPTIONS,
    ...Object.keys(state.uploadedFonts),
    ...fontOptions
  ];
};

const addIntegToState = (state: any, payload: any) => {
  if (!state[payload.panel]) state[payload.panel] = {};
  state[payload.panel][payload.type] = { data: payload };
};

const updateIntegrationsReducer = (state: any, action: any) => {
  state.integrations = action.payload.reduce(
    (curIntegs: any, newInteg: any) => {
      if (!curIntegs[newInteg.panel]) curIntegs[newInteg.panel] = {};
      curIntegs[newInteg.panel][newInteg.type] = {
        data: newInteg
      };
      return curIntegs;
    },
    {}
  );
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    integrations: {} as Record<string, any>,
    defaultFonts: [],
    uploadedFonts: {},
    translations: {},
    languageCodes: [],
    customAPIs: [] as APIService[],
    fontOptions: [...IMPORTANT_FONT_OPTIONS, ...NORMAL_FONT_OPTIONS],
    rolloutToken: '',
    quikFields: {} as Record<string, any[]>
  },
  reducers: {},
  extraReducers: (builder) => {
    addDefaultReducers(builder, createIntegration, OPERATION.CREATE);
    addDefaultReducers(builder, getIntegrations, OPERATION.READ);
    addDefaultReducers(builder, editIntegration, OPERATION.UPDATE);
    builder.addCase(createIntegration.fulfilled, (state, action) => {
      addIntegToState(state.integrations, action.payload);
    });
    builder.addCase(getIntegrations.fulfilled, updateIntegrationsReducer);
    builder.addCase(editIntegration.fulfilled, (state: any, action: any) => {
      addIntegToState(state.integrations, action.payload);
    });
    builder.addCase(
      switchIntegrationProperty.fulfilled,
      (state: any, action: any) => {
        const arg = action.meta.arg;
        state.integrations[arg.panelId][
          arg.integrationType
        ].data.secret_metadata.rollout_ids.push(action.payload.new);
      }
    );
    builder.addCase(uploadFont.fulfilled, (state: any, action: any) => {
      state.uploadedFonts = action.payload;
      updateFontOptions(state);
    });
    builder.addCase(exchangeOAuthToken.fulfilled, updateIntegrationsReducer);
    builder.addCase(revokeOAuthToken.fulfilled, updateIntegrationsReducer);
    builder.addCase(getDynamicFonts.fulfilled, (state: any, action: any) => {
      state.defaultFonts = action.payload.default;
      state.uploadedFonts = action.payload.uploaded;
      updateFontOptions(state);
    });
    builder.addCase(getTranslations.fulfilled, (state: any, action: any) => {
      state.translations = action.payload.translations;
      state.languageCodes = action.payload.codes;
    });
    builder.addCase(editTranslations.fulfilled, (state: any, action: any) => {
      state.translations = action.payload;
    });
    builder.addCase(fetchRolloutToken.fulfilled, (state: any, action: any) => {
      state.rolloutToken = action.payload.token;
    });
    builder.addCase(fetchQuikFields.fulfilled, (state: any, action: any) => {
      state.quikFields[action.meta.arg.formId] = action.payload;
    });
    builder.addCase(getCustomAPIs.fulfilled, (state: any, action: any) => {
      state.customAPIs = action.payload;
    });
    builder.addCase(createCustomAPI.fulfilled, (state: any, action: any) => {
      state.customAPIs.push(action.payload);
    });
    builder.addCase(editCustomAPI.fulfilled, (state: any, action: any) => {
      const id = action.meta.arg.customAPI.id;
      const index = state.customAPIs.indexOf(id);
      if (action.payload) {
        state.customAPIs[index] = action.payload;
      } else delete state.customAPIs[index];
    });
  }
});

export default integrationsSlice.reducer;
export {
  createIntegration,
  getIntegrations,
  switchIntegrationProperty,
  editIntegration,
  exchangeOAuthToken,
  revokeOAuthToken,
  uploadFont,
  getDynamicFonts,
  getTranslations,
  editTranslations,
  fetchRolloutToken,
  fetchQuikFields,
  getCustomAPIs,
  createCustomAPI,
  editCustomAPI,
  testCustomAPI
};
